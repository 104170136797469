import React, {ReactNode, useEffect, useRef, useState} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {Collapse} from "react-collapse";

import {Icon, IconTypes} from "../../icon";
import {meta} from "../../../../meta";
import {Wrap} from "../../wrap";
import {useClickInside, useClickOutside, useMedia} from "../../../../hooks";

import "./header.scss";

interface HeaderProps {
    children: ReactNode
}

interface HeaderLinkProps {
    text: string,
    to: string
}

export const Header = (props: HeaderProps) => {
    interface HeaderSocialProps {
        type: "facebook" | "twitter" | "instagram",
        username: string
    }

    const HeaderSocial = (props: HeaderSocialProps) => {
        const {type, username} = props;
        let link: string, icon: IconTypes;

        switch (type) {
            case "facebook":
                link = `https://facebook.com/${username}`;
                icon = "facebook";
                break

            case "twitter":
                link = `https://twitter.com/${username}`;
                icon = "twitter";
                break

            case "instagram":
                link = `https://instagram.com/${username}`;
                icon = "instagram";
                break
        }

        return (
            <a className="social-link" href={link} rel="noopener noreferrer" target="_blank">
                <Icon name={icon} size="small"/>
            </a>
        )
    }

    const {facebook, twitter, instagram} = meta.socials;

    const isMobile = useMedia(["(min-width: 576px)"], [false], true);
    const [open, setOpen] = useState(false);
    const {pathname, search} = useLocation();
    const ref = useRef(null);
    const socialRef = useRef(null);

    const toggleMenu = () => setOpen(prevState => !prevState);
    const toggleClose = () => {
        if (open) setOpen(false)
    };

    useEffect(toggleClose, [isMobile, pathname, search]);
    useClickOutside(toggleClose, ref);
    useClickInside(toggleClose, socialRef);

    const dropdownMenuClassNames = {
        collapse: "navbar-dropdown",
        content: "navbar-dropdown-menu wrap"
    };

    return (
        <header className="header" ref={ref}>
            <div className="social" ref={socialRef}>
                <Wrap>
                    <HeaderSocial type="facebook" username={facebook}/>
                    <HeaderSocial type="twitter" username={twitter}/>
                    <HeaderSocial type="instagram" username={instagram}/>
                </Wrap>
            </div>
            <div className="navbar">
                <Link to="/" className="brand">
                    <Icon name="shine" size="large"/>
                    <h1>SHINE.NET</h1>
                </Link>
                {isMobile ?
                    <button className={`toggle${open ? " active" : ""}`} onClick={toggleMenu}>
                        <Icon name="bars"/>
                    </button> :
                    <div className="navbar-links">
                        {props.children}
                    </div>
                }
            </div>
            {isMobile && <Collapse isOpened={open} theme={dropdownMenuClassNames}>
                {props.children}
            </Collapse>}
        </header>
    )
}

export const HeaderLink = (props: HeaderLinkProps) => {

    return (
        <NavLink to={props.to} className="navbar-link">{props.text}</NavLink>
    )
}

