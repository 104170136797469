import React, {ReactNode} from "react";

import {Wrap} from "../wrap";

import "./page-header.scss";

interface PageHeaderProps {
    title: string,
    subtitle?: string,
    children?: ReactNode
}

export const PageHeader = (props: PageHeaderProps) => {
    const {title, subtitle, children} = props;

    return (
        <header className="page-header">
            <Wrap>
                <h1>{title}</h1>
                {subtitle && <h5>{subtitle}</h5>}
                {children && <div>{children}</div>}
            </Wrap>
        </header>
    )
}
