/*
*   DO NOT MAKE CHANGES TO THIS FILE 
*   THIS FILE WAS GENERATED BY REACT-SCRIPTS-SVG
*   https://github.com/wllkle/react-scripts-svg
*/

import React, {CSSProperties} from "react";

import {iconList, IconTypesArray, IconTypes, INode} from "./defs";

const printableElements: string[] = ["title", "style"];

interface IconProps {
    name: IconTypes,
    className?: string | string[],
    style?: CSSProperties
}

const buildClassName = (className: string | string[] | undefined, icon: string = ""): string => {
    const classNames = ["icon", `icon-${icon}`];
    if (className) {
        if (Array.isArray(className)) {
            classNames.concat(className);
        } else {
            classNames.push(className);
        }
    }
    return classNames.map(c => c && c.trim()).join(" ");
}

const renderChildNodes = (nodes: INode[]) => (
    <>
        {nodes.map((node: INode) => {
            const {name, attributes, children} = node;
            const Tag = name.toString();
            const id = Math.random().toString(36).substring(2, 8);
            
            if (printableElements.includes(name)) {
                if (node.children.length > 0) {
                    const {value} = node.children[0]

                    // @ts-ignore
                    return (<Tag key={id}>{value}</Tag>);
                }
            }

            // @ts-ignore
            return (<Tag key={id} {...attributes}>{renderChildNodes(children)}</Tag>);
        })}
    </>
);

export const SVG = (props: IconProps) => {
    const {name, className, style} = props;
    const iconData = iconList[name] ? iconList[name] : null;

    let svgProps = {
        className: buildClassName(className, iconData?.name ? iconData.name : undefined)
    };
    
    if (style) svgProps["style"] = style;

    if (iconData) {
        const {viewBox, element} = iconData;
        svgProps["viewBox"] = viewBox;

        return (
            <svg {...svgProps}>
                {renderChildNodes(element)}
            </svg>
        );
    }
    
    return (
        <svg {...svgProps}/>
    );
}

export {IconTypesArray} from "./defs";
export type {IconTypes} from "./defs";
