import React from "react";
import classNames from "classnames";

import {IconTypes, SVG} from "./svg";

import "./icon.scss";

interface IconProps {
    name: IconTypes,
    size?: "small" | "medium" | "large"
}

export const Icon = (props: IconProps) => {
    const {name, size} = props;

    const className = classNames({
        "icon-s": size === "small",
        "icon-m": !size || size === "medium",
        "icon-l": size === "large",
    })

    return (
        <SVG name={name} className={className}/>
    )
}

export type {IconTypes} from "./svg";
