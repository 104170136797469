import React from "react";

import {Wrap} from "../../wrap";
// import {meta} from "../../../../meta";

import "./footer.scss";

export const Footer = () => {
    // const {twitter} = meta.socials

    return (
        <footer className="footer">
            <Wrap>
            </Wrap>
        </footer>
    );
}
