import React from "react";
import {PageHeader} from "../common/page-header";

export const Events = ()=>{

    return (
        <>
            <PageHeader title="Events" subtitle="Upcoming events"/>
        </>
    )
}
