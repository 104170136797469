export interface INode {
    name: string,
    type: string,
    value: string,
    attributes: object,
    children: INode[]
}

interface IIconList {
    [key: string]: {
        name: string,
        viewBox: string,
        element: INode[]
    }
}

export const iconList: IIconList = {
    bars: {
        name: "bars",
        viewBox: "0 0 448 512",
        element: [
            {
                name: "path",
                type: "element",
                value: "",
                attributes: {
                    d: "M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                },
                children: []
            }
        ]
    },
    facebook: {
        name: "facebook",
        viewBox: "0 0 320 512",
        element: [
            {
                name: "path",
                type: "element",
                value: "",
                attributes: {
                    d: "M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                },
                children: []
            }
        ]
    },
    instagram: {
        name: "instagram",
        viewBox: "0 0 448 512",
        element: [
            {
                name: "path",
                type: "element",
                value: "",
                attributes: {
                    d: "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                },
                children: []
            }
        ]
    },
    shine: {
        name: "shine",
        viewBox: "0 0 500 500",
        element: [
            {
                name: "g",
                type: "element",
                value: "",
                attributes: {
                    transform: "translate(0.000000,500.000000) scale(0.100000,-0.100000)",
                    stroke: "none"
                },
                children: [
                    {
                        name: "path",
                        type: "element",
                        value: "",
                        attributes: {
                            d: "M2375 4713 c-304 -19 -587 -93 -851 -222 -221 -108 -383 -222 -558\n-391 -300 -289 -511 -647 -611 -1039 -157 -612 -49 -1254 299 -1778 36 -54 91\n-129 122 -167 l56 -68 739 738 c407 407 739 742 739 746 0 4 -226 8 -502 8\nl-503 -1 3 53 c18 321 196 653 455 852 218 166 453 246 727 246 252 0 474 -68\n672 -205 l61 -43 356 360 c196 198 357 363 358 367 1 3 -33 34 -75 67 -344\n274 -771 440 -1217 473 -135 11 -163 11 -270 4z m345 -387 c221 -30 378 -78\n585 -181 83 -41 159 -82 170 -90 19 -15 15 -20 -85 -120 l-105 -105 -105 53\nc-435 218 -952 218 -1387 -1 -272 -138 -537 -403 -675 -675 -144 -286 -202\n-668 -145 -952 l12 -60 235 -5 235 -5 -279 -287 c-154 -159 -282 -288 -284\n-288 -2 0 -25 46 -53 103 -202 424 -234 913 -89 1367 42 129 165 375 247 490\n88 124 311 346 433 432 238 168 531 284 817 323 131 17 344 18 473 1z"
                        },
                        children: []
                    },
                    {
                        name: "path",
                        type: "element",
                        value: "",
                        attributes: {
                            d: "M3405 3230 l-740 -740 505 -2 505 -3 -3 -65 c-3 -85 -28 -208 -59\n-295 -137 -380 -438 -656 -830 -762 -89 -24 -115 -27 -283 -27 -122 -1 -204 4\n-240 12 -166 41 -321 107 -436 187 l-62 43 -362 -363 -362 -362 74 -60 c276\n-226 632 -388 998 -455 161 -29 497 -36 666 -14 480 63 933 286 1274 626 336\n336 551 764 627 1243 24 157 24 491 -1 642 -69 424 -221 763 -491 1098 -16 20\n-32 37 -35 37 -3 0 -338 -333 -745 -740z m741 75 c114 -244 163 -453 171 -727\n10 -322 -39 -568 -166 -842 -239 -513 -732 -904 -1282 -1016 -406 -83 -809\n-33 -1174 145 -60 30 -132 68 -159 86 l-49 31 105 105 105 105 103 -52 c317\n-159 677 -205 1020 -131 595 130 1053 595 1180 1196 27 128 37 366 21 490 -20\n152 13 134 -259 137 l-235 3 278 287 c153 158 283 284 288 281 5 -3 29 -47 53\n-98z"
                        },
                        children: []
                    }
                ]
            }
        ]
    },
    twitter: {
        name: "twitter",
        viewBox: "0 0 512 512",
        element: [
            {
                name: "path",
                type: "element",
                value: "",
                attributes: {
                    d: "M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                },
                children: []
            }
        ]
    }
};
        
export type IconTypes = "bars" |
	"facebook" |
	"instagram" |
	"shine" |
	"twitter";

export const IconTypesArray: string[] = [
    "bars",
	"facebook",
	"instagram",
	"shine",
	"twitter"
];
