import React from "react";
import {PageHeader} from "../common/page-header";

export const Venues = () => {

    return (
        <>
            <PageHeader title="Venues"/>
        </>
    )
}
