import React, {ReactNode} from "react";
import classNames from "classnames";

interface WrapProps {
    children: ReactNode,
    className?: string
    tag?: "div" | "main" | "nav",
}

export const Wrap = (props: WrapProps) => {
    const className = classNames("wrap", props.className);
    const Tag = props.tag ? props.tag : "div";

    return (
        <Tag className={className}>
            {props.children}
        </Tag>
    )
}
