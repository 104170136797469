import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import {Header, HeaderLink, Footer} from "./components/common/page";

import {Home} from "./components/home";
import {Events} from "./components/events";
import {Venues} from "./components/venues";

export const App = () => {
    return (
        <Router>
            <Header>
                <HeaderLink text="Events" to="/events"/>
                <HeaderLink text="Venues" to="/venues"/>
            </Header>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/events" element={<Events/>}/>
                <Route path="/venues" element={<Venues/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
}
